import { totalPurchasesAPI } from "@src/api/payments";
import { getCurrentMonthDays } from "@src/utils/current-month";
import { useQuery } from "@tanstack/react-query";
import useAuthService from "./useAuthService";
import { totalPurchasesCurrentMonth } from "@src/api/purchases";
import { GetAllPurchasesAPIParams } from "@src/lib/types/purchases";

type ServiceOperators = {
  totalPurchasesThisMonth: any;
};

export const usePaymentsService = (): Readonly<ServiceOperators> => {
  const { currentUser } = useAuthService();

  const { data } = useQuery({
    queryKey: ["current-month-purchases"],
    queryFn: async () => {
      const reqParams: GetAllPurchasesAPIParams = {
        // size: rowsPerPage,
        // page: newPage,
      };

      // if (filterUserID) reqParams.user_id = Number(filterUserID);
      // if (status) reqParams.status = status;
      // if (paymentOption) reqParams.payment_type_id = Number(paymentOption);
      // if (orderBy && order) reqParams.sort = `${orderBy},${order}`;
      // if (fromdate) reqParams.from_date = fromdate;
      // if (todate) reqParams.to_date = todate;
      // if (year) reqParams.year = year;
      // if (month) reqParams.month = month;
      const purchaseRes = await totalPurchasesCurrentMonth(reqParams);

      return purchaseRes || {};
    },
    enabled: !!currentUser?.id,
  });

  return {
    totalPurchasesThisMonth: data || {},
  };
};
