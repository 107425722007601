import { getCoinTransactionBetStatsAPI } from "@src/api/coin-transactions";
import { useQuery } from "@tanstack/react-query";

const useCoinTransactionBetStats = (period: string) => {
	const { data, isLoading } = useQuery({
		queryKey: ["coin-transaction-bet-chart", period],
		queryFn: async () => {
			const result = await getCoinTransactionBetStatsAPI(period);
			return result;
		},
	});

	return {
		data,
		isLoading,
	};
};

export default useCoinTransactionBetStats;