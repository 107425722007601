import Config from "@src/utils/api/config";
import axiosClient from "@src/utils/axios-client";

export const mostPopularDealAPI = async () => {
  try {
    const result = await axiosClient.get("/platform/deals/most-popular");
    return result?.data;
  } catch (error) {
    throw error;
  }
};

export const mostPopularGoldShopItemAPI = async () => {
  try {
    const result = await axiosClient.get(
      "/platform/gold-shop-items/most-popular"
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

export const mostPopularShopItemAPI = async () => {
  try {
    const result = await axiosClient.get("/platform/shop-items/most-popular");
    return result?.data;
  } catch (error) {
    throw error;
  }
};

export const totalSubscriptionsCountAPI = async () => {
  try {
    const result = await axiosClient.get(
      "/platform/subscriptions/purchased/count"
    );
    return result?.data?.count || 0;
  } catch (error) {
    throw error;
  }
};

export const totalCompletedMissionsCountAPI = async () => {
  try {
    const result = await axiosClient.get(
      "/platform/missions/reward-claimed/count?partnerId=" + Config.PARTNER_ID
    );
    return result?.data?.count || 0;
  } catch (error) {
    throw error;
  }
};

export const SubscriptionsChartAPI = async (period: string) => {
  try {
    const result = await axiosClient.get(
      "/subscription/chart", { params: { period }}
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

export const PurchaseChartAPI = async (period: string) => {
  try {
    const result = await axiosClient.get(
      "/payment/chart", { params: { period }}
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

export const MissionsActivityChartAPI = async (period: string) => {
  try {
    const result = await axiosClient.get(
      "/platform/missions/weeks/chart", 
      { 
        params: { 
          partnerId: Config.PARTNER_ID,
          period 
        }
      }
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

export const MissionsActivityTrackerAPI = async (params: any) => {
  try {
    const requestParams = {
      page: 1,
      size: 10,
      partnerId: Config.PARTNER_ID,
      // sort: "transactionDate,desc",
      ...params,
    };

    const result = await axiosClient.get(
      "/platform/missions", 
      { 
        params: requestParams
      }
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

// FETCH USERS MISSIONS
export const MissionsActivityAPI = async (params: any) => {
  try {
    const requestParams = {
      page: 1,
      size: 10,
      partnerId: Config.PARTNER_ID,
      // sort: "transactionDate,desc",
      ...params,
    };

    const result = await axiosClient.get(
      "/platform/missions/weeks/users", 
      { 
        params: requestParams
      }
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

// FETCH GOLD SHOP
export const GoldShopAPI = async (params: any) => {
  try {
    const requestParams = {
      page: 1,
      size: 10,
      partnerId: Config.PARTNER_ID,
      ...params,
    };

    const result = await axiosClient.get(
      "/platform/gold-shop-items", 
      { 
        params: requestParams
      }
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

// FETCH GOLD SHOP TOTAL EXCHANGE
export const GoldShopTotalGoldExchangeAPI = async (params: any) => {
  try {
    const requestParams = {
      partnerId: Config.PARTNER_ID,
      ...params,
    };

    const result = await axiosClient.get(
      "/platform/gold-shop-items/stats", 
      { 
        params: requestParams
      }
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

// FETCH GOLD SHOP USER'S PURCHASE
export const GoldShopUsersPurchaseAPI = async (params: any) => {
  try {
    const requestParams = {
      page: 1,
      size: 10,
      partnerId: Config.PARTNER_ID,
      ...params,
    };

    const result = await axiosClient.get(
      "/platform/gold-shop-items/users", 
      { 
        params: requestParams
      }
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

// FETCH SHOP DATA
export const ShopDataAPI = async (params: any) => {
  try {
    const requestParams = {
      page: 1,
      size: 10,
      partnerId: Config.PARTNER_ID,
      ...params,
    };

    const result = await axiosClient.get(
      "/platform/shop-items", 
      { 
        params: requestParams
      }
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

// FETCH SHOP DATA STATS
export const ShopDataStatAPI = async (params: any) => {
  try {
    const requestParams = {
      partnerId: Config.PARTNER_ID,
      ...params,
    };

    const result = await axiosClient.get(
      "/platform/shop-items/stats", 
      { 
        params: requestParams
      }
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

// FETCH SHOP DATA USER'S PURCHASE
export const ShopDataUsersPurchaseAPI = async (params: any) => {
  try {
    const requestParams = {
      page: 1,
      size: 10,
      partnerId: Config.PARTNER_ID,
      ...params,
    };

    const result = await axiosClient.get(
      "/platform/shop-items/users", 
      { 
        params: requestParams
      }
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

// FETCH PROMOTIONS DATA
export const PromotionsDataAPI = async (params: any) => {
  try {
    const requestParams = {
      page: 1,
      size: 10,
      partnerId: Config.PARTNER_ID,
      ...params,
    };

    const result = await axiosClient.get(
      "/platform/deals", 
      { 
        params: requestParams
      }
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

// FETCH PROMOTIONS DATA STATS
export const PromotionsDataStatAPI = async (params: any) => {
  try {
    const requestParams = {
      partnerId: Config.PARTNER_ID,
      ...params,
    };

    const result = await axiosClient.get(
      "/platform/deals/stats", 
      { 
        params: requestParams
      }
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

// FETCH PROMOTIONS DATA USER'S PURCHASE
export const PromotionsDataUsersPurchaseAPI = async (params: any) => {
  try {
    const requestParams = {
      page: 1,
      size: 10,
      partnerId: Config.PARTNER_ID,
      ...params,
    };

    const result = await axiosClient.get(
      "/platform/deals/users", 
      { 
        params: requestParams
      }
    );

    return result?.data;
  } catch (error) {
    throw error;
  }
};

