import React, { ChangeEvent, createContext, useCallback, useContext } from 'react';
import _debounce from 'lodash/debounce';

import { useInputHelper } from '@src/utils/inputs-helper';

import { MostPlayedContextStateType, MostPlayedContextValueType, MostPlayedProviderProps } from '@src/lib/types/most-played';
import { useNavigate } from 'react-router-dom';

const initialState: MostPlayedContextStateType = {
  open: '',
  anchorEl: null,
  page: 0,
  provider: null,
  order: 'desc',
  orderBy: 'totalSilverBet',
  search: '',
  debouncedSearch: '',
  rowsPerPage: 10,
  filterSidebarOpen: false,
}

export const MostPlayedContext = createContext<MostPlayedContextValueType>({} as MostPlayedContextValueType);

const TransactionProvider = ({ children }: MostPlayedProviderProps) => {
  const navigate = useNavigate();

  const { state, onDispatch } = useInputHelper(initialState);

  const handlePageChange = (event: unknown, newPage: number) => {
    onDispatch('page')(newPage);
  }

  const handleRequestSort = (property: string) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    const order = isAsc ? 'desc' : 'asc';
    navigate(`/dashboard/mostplayed?sort=${order}&sortBy=${property}`);
    onDispatch('order')(order);
    onDispatch('orderBy')(property);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    onDispatch('rowsPerPage')(event.target.value);
  }

  const debouncedSearchFn = (value: string) => {
    onDispatch('page')(0);
    onDispatch('debouncedSearch')(value);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearch = useCallback(_debounce(debouncedSearchFn, 1000), []);

  const handleSearch = (value: string) => {
    onDispatch('search')(value);
    handleDebouncedSearch(value);
  }

  const handleOpenFilterSidebar = () => {
    onDispatch('filterSidebarOpen')(true);
  }

  const handleCloseFilterSidebar = () => {
    onDispatch('filterSidebarOpen')(false);
  }

  const handleClearFilters = () => {
    onDispatch('fromDate')(null);
    onDispatch('toDate')(null);
    onDispatch('filterSidebarOpen')(false);
  }

  const value = {
    state,
    onDispatch,
    handlePageChange,
    handleRequestSort,
    handleRowsPerPageChange,
    handleSearch,
    handleClearFilters,
    handleOpenFilterSidebar,
    handleCloseFilterSidebar,
  };

  return (
    <MostPlayedContext.Provider value={value}>
      {children}
    </MostPlayedContext.Provider>
  );
};

export const useMostPlayedContext = () => {
  return useContext(MostPlayedContext);
}

export default TransactionProvider;
