/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// @mui
import {
  Card,
  Container,
  Table,
  TableContainer,
  TablePagination
} from '@mui/material';

// components
import Scrollbar from '@src/components/scrollbar';

// mock
import DataTableRow from '@src/components/data-table/data-row';
import TableHeader from '@src/components/data-table/header';
import Config from '@src/utils/api/config';
import { TABLE_HEAD_REDEEM_TRANSACTION, TABLE_HEAD_REDEEM_TRANSACTION_PALMSPLAY } from '@src/utils/mockdata/table-headers';

import { useRedeemTransactionsService } from './hooks';
import { useRedeemTransactionsContext } from './provider';
import TableRowItem from './table-row';
import Toolbar from './toolbar';

const { isPalmsPlay } = Config;

const Body = () => {
  const { state, tableContainerRef, handlePageChange, handleRequestSort, handleRowsPerPageChange, handleOpenMenu } = useRedeemTransactionsContext();
  const { data, isLoading, isRefreshing, redeemTransactions } = useRedeemTransactionsService();

  console.log("redeemTransactions", redeemTransactions)

  return (
    <>
      <Container>
        <Toolbar />
        <Card sx={{borderRadius: "25px", px: "23px", py: "10px"}}>
          <Scrollbar>
            <TableContainer ref={tableContainerRef} sx={{ overflowX: 'auto' }}>
              <Table>
                <TableHeader
                  order={state.order}
                  orderBy={state.orderBy}
                  headLabel={isPalmsPlay ? TABLE_HEAD_REDEEM_TRANSACTION_PALMSPLAY : TABLE_HEAD_REDEEM_TRANSACTION}
                  onRequestSort={handleRequestSort}
                />
                <DataTableRow
                  colSpan={9}
                  isLoading={isLoading}
                  isRefreshing={isRefreshing}
                  headers={isPalmsPlay ? TABLE_HEAD_REDEEM_TRANSACTION_PALMSPLAY : TABLE_HEAD_REDEEM_TRANSACTION}
                  data={redeemTransactions}
                  renderItem={({ item, index }: any) => {
                    return (
                      <TableRowItem
                        key={`${index}`}
                        {...item}
                        handleOpenMenu={handleOpenMenu(item)}
                      />
                    )
                  }} />
              </Table>

            </TableContainer>
          </Scrollbar>
          <TablePagination
            component="div"
            page={state.page || 0}
            count={data?.totalRecords || 0}
            rowsPerPage={state.rowsPerPage || 10}
            onPageChange={handlePageChange}
            rowsPerPageOptions={[10, 20, 50]}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Card>
      </Container>
    </>
  );
}

export default React.memo(Body);
