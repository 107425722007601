import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import { downloadExcel } from "@src/utils/download-helper";

import {
  TransactionType,
  UsePurchasesServiceOperators
} from "@src/lib/types/purchases";

import { usersClaimedAPI } from "@src/api/code-generator";
import { CodeGeneratorAPIParams } from "@src/lib/types/code-generator";
import { TABLE_HEAD_PURCHASE } from "@src/utils/mockdata/table-headers";
import numeral from "numeral";
import { useCodeGeneratorContext } from "../provider";
import { useProviderContext } from "./provider";

export const usePurchasesService = (): Readonly<UsePurchasesServiceOperators> => {
  const [params] = useSearchParams();
  const userId = params.get("u");
  const year = params.get("year");
  const month = params.get("month");

  const { state: {editDetails} } = useCodeGeneratorContext();
  const { state, handlePageChange } = useProviderContext();
  const {
    page,
    fromdate,
    todate,
    order,
    orderBy,
    rowsPerPage,
    userid,
    status,
    paymentOption,
  } = state || {};
  const newPage = (page || 0) + 1;
  const filterUserID = userid || userId;

  const purchasesListQueryKey = [
    "purchases",
    {
      year,
      month,
      newPage,
      fromdate,
      todate,
      userid: filterUserID,
      order,
      orderBy,
      rowsPerPage,
      status,
      paymentOption,
      id: editDetails?.id,
    },
  ];

  const { data, isLoading, isRefetching, refetch } = useQuery({
    queryKey: purchasesListQueryKey,
    queryFn: async () => {
      const reqParams: CodeGeneratorAPIParams = {
        size: rowsPerPage,
        page: newPage,
        id: editDetails?.id,
      };

      if (orderBy && order) reqParams.sort = `${orderBy},${order}`;
      if (fromdate) reqParams.fromDate = fromdate;
      if (todate) reqParams.toDate = todate;

      const res = await usersClaimedAPI(reqParams);

      return res;
    },
  });

  const onExportPurchasesList = useMutation({
    mutationKey: ["export-purchases-list"],
    mutationFn: async () => {
      const reqParams: CodeGeneratorAPIParams = {
        id: editDetails?.id,
      };

      if (orderBy && order) reqParams.sort = `${orderBy},${order}`;

      if (fromdate) reqParams.from_date = fromdate;
      if (todate) reqParams.to_date = todate;

      const res = await usersClaimedAPI(reqParams);

      const newJson = res.data.items.map((item: TransactionType) => {
        const result: Record<string, any> = {};

        TABLE_HEAD_PURCHASE.forEach(({ id, label = "" }) => {
          let fieldValue: any;

          if (id === 'userID') fieldValue = item.id;
          if (id === 'u.displayName') fieldValue = item.user.displayName;
          if (id === 'purchaseItemName') fieldValue = item.purchaseItemName;
          if (id === 'amount') fieldValue = numeral(item.amount || 0).format("0,000");
          if (id === 'status') fieldValue = item.status;
          if (id === 'transactionDate') fieldValue = item.transactionDate ? moment.parseZone(item.transactionDate).format("MM-DD-YYYY hh:mm A") : "-";

          result[label] = fieldValue;
        });

        return result;
      });

      downloadExcel(newJson, "export-purchase");
    },
  });

  const onRefreshPurchases = () => {
    handlePageChange(null, 0);
    refetch();
  };

  return {
    data,
    purchases: data?.items || [],
    isLoading,
    isRefreshing: isRefetching,
    onRefreshPurchases,
    onExportPurchasesList,
  };
};
