import { Container, Grid } from "@mui/material";
import { AppWidgetSummary } from "@src/sections/@dashboard/app";
import palette from '@src/theme/palette';
import Config from "@src/utils/api/config";
import moment from "moment";
import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUsersService } from "./hooks";
import { useUsersContext } from "./provider";

const { isFortune8 } = Config;

const WrappedHeader = () => {
	const navigate = useNavigate();
	const [params] = useSearchParams();
	const onlyActiveUsers = params.get('activeUsers') === 'true';
	const { state, handleReset, handleToggleUserDemographics,  } = useUsersContext();
	const { totalUsers, totalUsersThisMonth, totalActiveUsers } = useUsersService({
		fetchTotalUsers: true,
		fetchTotalUsersThisMonth: true,
		fetchTotalActiveUsers: true
	});

	const dateLabel = useMemo(() => {
		if(onlyActiveUsers){
			return state.fromDate && state.toDate ? 
			`${moment(state.fromDate).format("DD MMM YYYY")} - ${moment(state.toDate).format("DD MMM YYYY")}` 
			: `${moment().format("DD MMM YYYY")}`;
		}

		return `${moment().format("DD MMM YYYY")}`;
	}, [state.fromDate, state.toDate, onlyActiveUsers]);

	return (
		<Container sx={{ marginBottom: 5 }}>
			<Grid container spacing={{
					xl: 2.5,
					lg: 2.5,
					md: 2.5,
					sm: 1.5,
					xs: 1.5
				}}>
				<Grid item xs={6} sm={6} md={3} >
					<AppWidgetSummary
						title="Total No. of Active Users"
						value={totalActiveUsers || 0}
						color={palette.goldenDream.dark}
						icon={'fa-solid:user-clock'}
						activeIcon={onlyActiveUsers ? "mdi:chevron-up" : "mdi:chevron-down"}
						dateValue={dateLabel}
						onClick={() => {
							handleReset();
							if(onlyActiveUsers){
								return navigate('/dashboard/users');
							}else{
								return navigate('/dashboard/users?activeUsers=true');
							}
						}}
					/>
				</Grid>

				<Grid item xs={6} sm={6} md={3} >
					<AppWidgetSummary
						disabled
						title="Total Users"
						value={totalUsers}
						color={palette.DarkTurquoise.dark}
						icon={'fa-solid:users'}
					/>
				</Grid>

				<Grid item xs={6} sm={6} md={3} >
					<AppWidgetSummary
						disabled
						title="New Users This Month"
						value={totalUsersThisMonth}
						color={palette.peach.dark}
						icon={'mdi:users-plus'}
					/>
				</Grid>

				{isFortune8 && (
					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary
							onClick={() => handleToggleUserDemographics()}
							noAmount
							title="Users Demographics"
							color={palette.violet.dark}
							labelProps={{
								sx: { fontSize: "15px", lineHeight: "19px", color: "#5922B8" },
							}}
							icon={'ic:baseline-equalizer'}
						/>
					</Grid>
				)}
			</Grid>
		</Container>
	)
}

const Header = () => {
	const { accessControl } = useUsersContext();
	const { allowDataDisplay } = accessControl || {};

	if (!allowDataDisplay) return null;

	return <WrappedHeader />;
}

export default Header