import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _reduce from "lodash/reduce";
import moment from "moment";
import { GetRedeemTransactionsAPIParams, UseRedeemTransactionsServiceOperators } from "@src/lib/types/redeem-transactions";
import { downloadExcel } from "@src/utils/download-helper";
import { exportRedeemTransactionsListAPI, getRedeemStats, getRedeemTransactionDocumentAPI, getRedeemTransactionsAPI, updateRedeemTransactionAPI } from "@src/api/redeem-transactions";
import { useRedeemTransactionsContext } from "./provider";
import Config from "@src/utils/api/config";

const { isPalmsPlay } = Config;

export const useRedeemTransactionsService = 
(args?: any): Readonly<UseRedeemTransactionsServiceOperators> => {
	const queryClient = useQueryClient();
	const { allowFetchStats } = args || {};

	const { state, handlePageChange } = useRedeemTransactionsContext();
	const {
			page,
			debouncedSearch,
			rowsPerPage,
			order,
			orderBy,
			selected,
			fromDate,
			toDate
	} = state || {};
	const newPage = (page || 0) + 1;

	const redeemTransactionsListQueryKey = [
		'redeem-transactions',
		{
			newPage,
			debouncedSearch,
			rowsPerPage,
			order,
			orderBy,
			fromDate,
			toDate
		}
	];

	const userDocumentQueryKey = [
		'redeem-userdocument',
		{ 
			userId: selected?.userID,
			newPage,
			debouncedSearch,
			rowsPerPage,
			order,
			orderBy,
			fromDate,
			toDate
		}
	];

	const { data, refetch, isRefetching: isRefreshing, isLoading } = useQuery({
		queryKey: redeemTransactionsListQueryKey,
		queryFn: async () => {
			const reqParams: GetRedeemTransactionsAPIParams = {
				size: rowsPerPage,
				page: newPage,
			};

			if (debouncedSearch) reqParams.userID = Number(debouncedSearch);
			if (orderBy && order) reqParams.sort = `${orderBy},${order}`;
			if (fromDate) reqParams.from_date = fromDate;
			if (toDate) reqParams.to_date = toDate;

			const result = await getRedeemTransactionsAPI(reqParams);

			return result;
		},
	});

	const { data: userDocumentData, isLoading: isLoadingUserDocuments } = useQuery({
		queryKey: userDocumentQueryKey,
		queryFn: async () => {
			if (selected?.userID) {
				const result = await getRedeemTransactionDocumentAPI(selected?.userID);
				return result;
			}
		},
		enabled: !!selected?.userID
	});

	const { data: redeemStats } = useQuery({
		queryKey: userDocumentQueryKey,
		queryFn: async () => {
			if (allowFetchStats) {
				const reqParams: GetRedeemTransactionsAPIParams = {
					size: rowsPerPage,
					page: newPage,
			};

			if (orderBy && order) reqParams.sort = `${orderBy},${order}`;
			if (fromDate) reqParams.from_date = fromDate;
			if (toDate) reqParams.to_date = toDate;

				const result = await getRedeemStats(reqParams);
				return result;
			}
		},
		enabled: allowFetchStats
	});

	const onRefreshRedeemTransactionsList = () => {
			handlePageChange(null, 0);
			refetch();
	};

	const onExportRedeemTransactionsList = useMutation({
		mutationKey: ["export-redeem-transactions-list"],
		mutationFn: async () => {
			const reqParams: GetRedeemTransactionsAPIParams = {};

			if (debouncedSearch) reqParams.userID = Number(debouncedSearch);
			if (orderBy && order) reqParams.sort = `${orderBy},${order}`;
			if (fromDate) reqParams.from_date = fromDate;
			if (toDate) reqParams.to_date = toDate;

			const res = await exportRedeemTransactionsListAPI(reqParams);

			const newJson = _reduce(
				res.items,
				(result: any, value: any, key) => {
					if(isPalmsPlay){
						result.push({
							'User ID': value.userID,
							'User Name': value.user?.displayName,
							'Amount PG': value.goldAmount,
							'Transaction Date': moment.parseZone(value.requestDate).format("MM-DD-YYYY hh:mm A"),
						});
					}else{
						result.push({
							id: value.id,
							'User ID': value.userID,
							'Redeem Type': value.redeemTypeID,
							'Redeem Status': value.redeemStatusID,
							'Gold Amount': value.goldAmount,
							'Request Date': moment.parseZone(value.requestDate).format("MM-DD-YYYY hh:mm A"),
						});
					}

					return result;
				},
				[]
			);

			downloadExcel(newJson, "export-redeem-transactions");
		}
	});

	const onUpdateRedeemTransaction = useMutation({
		mutationKey: ['update-redeem-transaction'],
		mutationFn: async (formData: any) => {
			const res = await updateRedeemTransactionAPI(formData);
			return res;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: redeemTransactionsListQueryKey });
		}
	});

	return {
		data,
		isLoading,
		redeemStats,
		isRefreshing,
		userDocumentData,
		isLoadingUserDocuments,
		onUpdateRedeemTransaction,
		onExportRedeemTransactionsList,
		onRefreshRedeemTransactionsList,
		redeemTransactions: data?.items || [],
	};
}