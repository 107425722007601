import { Container, Grid } from "@mui/material";
import SvgColor from "@src/components/svg-color";
import { AppWidgetSummary } from "@src/sections/@dashboard/app";
import palette from '@src/theme/palette';
import Config from "@src/utils/api/config";
import { useRedeemTransactionsService } from "./hooks";
import moment from "moment";
import { useRedeemTransactionsContext } from "./provider";
import { useMemo } from "react";

const Header = () => {
	const { state } = useRedeemTransactionsContext();
	const { redeemStats } = useRedeemTransactionsService({ allowFetchStats: true});
	const title = useMemo(() => {
		const label = Config.isFortune8 ? "Amount of GWz Gold" : "Amount of PG Redeemed";
		return `${label} ${state.fromDate && state.toDate ? 
			`(${moment(state.fromDate).format("DD MMM YYYY")} - ${moment(state.toDate).format("DD MMM YYYY")})` 
			: `(${moment().format("MMMM")})`}`
	},[state.fromDate, state.toDate]);

	return (
		<Container sx={{ my: 1 }}>
			<Grid container spacing={{
					xl: 2.5,
					lg: 2.5,
					md: 2.5,
					sm: 1.5,
					xs: 1.5
				}}>
				<Grid item xs={12} sm={6} md={6} >
					<AppWidgetSummary
						disabled
						title={title}
						color={palette.goldenDream.darker}
						value={redeemStats?.totalGoldAmount ?? 0}
						icon={<SvgColor src={`/assets/icons/ic_redeem_palms.svg`} sx={{ width: 24, height: 24 }} />}
					/>
				</Grid>
			</Grid>
		</Container>
	)
}

export default Header;