import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import Header from './header';
import MonthlyPalmsPoint from './monthly-palms-point';
import PurchaseRecords from './purchase-records';
import PurchaseProvider from './provider';

const PurchaseScreen = () => {
  const [params] = useSearchParams();
  const year = params.get("year");
  const month = params.get("month");
  const [screen, setScreen] = useState(0);

  useEffect(() => {
    if (year && month) {
      setScreen(0);
    }
  }, [year, month]);

  return (
    <PurchaseProvider>
      <Helmet>
        <title> Purchases </title>
      </Helmet>
      <Header sceenIndex={screen} onChangeScreen={setScreen} />
      {screen === 0 ? <PurchaseRecords /> : <MonthlyPalmsPoint />}
    </PurchaseProvider>
  );
}

export default PurchaseScreen;
