import axiosClient from "@src/utils/axios-client";
import Config from "@src/utils/api/config";

const { PARTNER_ID } = Config

export const gameCategoriesListAPI = async () => {
    try {
        const result = await axiosClient.get("/game-categories?partnerId=" + PARTNER_ID);
        return result.data.data || {};
    } catch (error: any) {
        throw error.response.data.error;
    }
};
