import Body from './body';
import PurchasesFilterSidebar from './filter';

const PurchaseRecords = () => {

  return (
    <>
      <Body />
      <PurchasesFilterSidebar />
    </>
  );
}

export default PurchaseRecords;
