import {
  Button,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Iconify from '@src/components/iconify/Iconify';
import Config from '@src/utils/api/config';
import { redeemStatusName, redeemTypeName } from '@src/utils/filter-helper';
import moment from 'moment';
import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';

const { isPalmsPlay } = Config;

const TableRowItem = (props: any) => {
  const navigate = useNavigate();

  const onViewUserDetails = () => {
    navigate(`/dashboard/users?u=${props.userID}`);
  }

  if (isPalmsPlay) {
    return (
      <TableRow hover role="checkbox">
        <TableCell onClick={onViewUserDetails} component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" ml={2} spacing={1}>
            <Button>
              <Typography variant="subtitle2" noWrap sx={{ textDecoration: 'underline' }}>
                {props.userID}
              </Typography>
            </Button>
          </Stack>
        </TableCell>
        <TableCell align="inherit">{props.user?.displayName || '-'}</TableCell>
        <TableCell align="center">{numeral(props.goldAmount).format("0,000.00")}</TableCell>
        <TableCell align="center">{moment.parseZone(props.requestDate).format("MM-DD-YYYY hh:mm A")}</TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow hover role="checkbox">
      <TableCell align="inherit">{props.id}</TableCell>
      <TableCell onClick={onViewUserDetails} component="th" scope="row" padding="none">
        <Stack direction="row" alignItems="center" ml={2} spacing={1}>
          <Button>
            <Typography variant="subtitle2" noWrap sx={{ textDecoration: 'underline' }}>
              {props.userID}
            </Typography>
          </Button>
        </Stack>
      </TableCell>
      <TableCell align="inherit">{redeemTypeName(props.redeemTypeID)}</TableCell>
      <TableCell align="inherit">{redeemStatusName(props.redeemStatusID)}</TableCell>
      <TableCell align="inherit">{numeral(props.goldAmount).format("0,000.00")}</TableCell>
      <TableCell align="left">{moment.parseZone(props.requestDate).format("MM-DD-YYYY hh:mm A")}</TableCell>
      <TableCell align="right">
        <IconButton size="large" color="inherit" onClick={props.handleOpenMenu}>
          <Iconify icon={'eva:more-vertical-fill'} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default TableRowItem;
