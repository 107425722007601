import { useQuery } from "@tanstack/react-query";

import {
  MissionsActivityChartAPI,
  totalCompletedMissionsCountAPI
} from "@src/api/platform-tools";

import { UsePlatformToolsServiceOperators } from "@src/lib/types/platform-tools";

type Props = {
  period?: string;
}

export const usePlatformToolsServices =
  ({period}: Props): Readonly<UsePlatformToolsServiceOperators> => {

    const { data: totalMissionsCompleted } = useQuery({
      queryKey: ["total-number-missions-completed"],
      queryFn: async () => {
        const result = await totalCompletedMissionsCountAPI();
        return result;
      },
    });

    const { data: missionActivityChart, refetch: refetchMissionActivityChart } = useQuery({
      queryKey: ["mission-activity-charts", period],
      queryFn: async () => {
        const result = await MissionsActivityChartAPI(period || "MONTHLY");

        return result;
      },
    });

    return {
      totalMissionsCompleted,
      missionActivityChart,
      refetchMissionActivityChart,
    };
  };
