import {
  TableCell,
  TableRow
} from '@mui/material';

const TableRowItem = (props: any) => {
  return (
    <TableRow hover role="checkbox">
      <TableCell align="inherit" >{props.userId}</TableCell>
      <TableCell align="inherit" >{props.displayName}</TableCell>
      <TableCell align="center" >{props.transactionDate}</TableCell>
      <TableCell align="center" >{props.packageName}</TableCell>
    </TableRow>
  );
}

export default TableRowItem;
